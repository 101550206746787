import CartUpdateService from "../../../services/cartUpdate.service";
import actions from "./cartUpdate.actions";
import Cookies from 'universal-cookie';
import { checkOutAsync } from "../checkout/checkout.thunks";

export const cartUpdateAsync = (stockID, qty, inputType, isAdding) => (dispatch) => {

	dispatch(actions.cartUpdateLoadStart());
	const cookies = new Cookies();

	dispatch(checkOutAsync(0));

	if (qty > 100) {
		qty = 20;
	}

	CartUpdateService.updateCartWishList(
		cookies.get('sc_id'),
		cookies.get('sd_auth'),
		cookies.get('_scid'),
		navigator.userAgent,
		cookies.get('_shopID'),
		stockID,
		qty, inputType, isAdding)
		.then((response) => {
			console.log(response);
			dispatch(actions.cartUpdateLoadSuccess(response.data));
		})
		.catch((error) => dispatch(actions.cartUpdateLoadError(error.message)));
};