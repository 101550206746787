import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Select2 from 'react-select2-wrapper';
import Icofont from 'react-icofont';
import OwlCarousel from 'react-owl-carousel3';
import ProductBox from './ProductBox';

import CategoriesCarousel from '../common/CategoriesCarousel';

const TopSearch = (props) => {

	return (
		<CategoriesCarousel {...props} />
	);
}

export default TopSearch;