import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel3';
import TopSearch from './home/TopSearch';
import ProductBox from './home/ProductBox';
import CardItem from './common/CardItem';
import SectionHeading from './common/SectionHeading';
import FontAwesome from './common/FontAwesome';
import { connect } from 'react-redux';
import CategoriesCarousel from './common/CategoriesCarousel';

import { useDispatch, useSelector } from "react-redux";
import { loadFlyerAsync } from "../redux/reducers/flyer/flyer.thunks";
import { loadProductsAsync } from "../redux/reducers/products/products.thunks";
import { cartUpdateAsync } from "../redux/reducers/cartUpdate/cartUpdate.thunks";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { ShimmerCircularImage, ShimmerBadge, ShimmerButton, ShimmerSimpleGallery } from "react-shimmer-effects";
import { ImageViewer } from "react-image-viewer-dv"
import BestSeller from './common/BestSeller';

import { useParams } from "react-router-dom";

const Index = (props) => {

	const location = useLocation();
	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isFlyerLoading, flyer, flyerErrorMessage } = useSelector((state) => state.flyerReducer);
	const { isProductLoading, products, productsErrorMessage } = useSelector((state) => state.productsReducer);

	const [spacing, setSpacing] = React.useState(2);
	//	this.updateCart = this.updateCart.bind(this);


	//console.log(location.pathname.match.params.shopCode);
	// console.log(props.route);
	// console.log(props);
	// console.log(location.pathname);
	// console.log(location);

	const { shopCode } = useParams();
	// console.log(shopCode);

	// console.log(location.pathname.match.params.shopCode);
	// const { idLancamento } = this.props.route.params;
	// alert(this.props.match.params.shopCode);

	// alert(this.props.match.params.shopCode);

	// console(URLSearchParams(this.props.location.search).get("shopCode"))

	useEffect(() => {

		if (shop) {
			dispatch(loadFlyerAsync(shop.ShopDetails.ShopID, "", ""));
		}

		if (shop) {
			dispatch(loadProductsAsync(shop.ShopDetails.ShopID, 0, "", true, "", "", ""));
		}

	}, [shop])

	const handleCategoryClick = (cat) => {
		alert("Hai");
		// dispatch(loadProductsAsync(shop.ShopDetails.ShopID, cat.CategoryID, true, "", "", ""));
	}

	function updateCart(stockID, quantity) {
		dispatch(cartUpdateAsync(stockID, quantity, 1, true));
	}

	return (
		<>
			{isLoading && <ShimmerSimpleGallery imageType="circular" imageHeight={50} caption row={1} col={4} style={{ marginTop: 115 }} />}

			{/* Category scrolling */}

			<Container className="section" style={{ marginTop: 115 }}>
				<TopSearch {...props} />
			</Container>

			<section className="section pt-1 pb-5 bg-white">
				<Row style={{ marginTop: 0, marginRight: 0, marginLeft: 0 }}>

					{products && <>
						{products.Data.map(productRow => {
							return (

								<Col md={2} xs={6} className="mb-6 pb-2">

									<BestSeller
										{...props}
										updateCart={updateCart}
										shopCode={shopCode}
										id={productRow.StockID}
										shopID={productRow.ShopID}
										title={productRow.ItemName}
										subTitle={productRow.OfferName}
										imageAlt='Product'
										image={productRow.ImageData[0].ImagePath}
										imageClass='img-fluid item-img'
										price={productRow.SellingPrice}
										priceUnit='AED '
										isNew={false}
										showPromoted={false}
										promotedVariant='dark'
										favIcoIconColor='text-danger'
										rating='3.1 (300+)'
										oldPrice={productRow.ActualPrice}
										discountPercentage={productRow.Discount}

									//getValue={this.getQty}
									/>
								</Col>
							)
						}
						)}
					</>}



				</Row>
			</section>
		</>
	);
}
const options = {
	responsive: {
		0: {
			items: 1,
		},
		600: {
			items: 2,
		},
		1000: {
			items: 4,
		},
		1200: {
			items: 4,
		},
	},

	lazyLoad: true,
	pagination: false.toString(),
	loop: true,
	dots: false,
	autoPlay: 2000,
	nav: true,
	navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"]
}


export default Index;
