import apiClient from "../helpers/apiClient";

class PlaceOrderService {

	confirmPlaceOrder = (
		userID = 0,
		authKey = "",
		session = "test",
		browserAgent = "empty",
		shopID = 0,
		addressID = 0,
		isSuggestedDelivery = false,
		strSuggestedDate = "2022-01-01",
		strRemark = "",
		blnIsShopVisit = true,
		blnIsGuest = true,
		strName = "",
		strPhone = "",
		strEmail = "",
		strAddress = "",
		strIpAddress = "1") => apiClient().post('/PlaceOrderWithSession',
			{
				UserID: userID,
				AuthKey: authKey,
				Session: session,
				BrowserAgent: browserAgent,
				ShopID: shopID,
				AddressID: addressID,
				IsSuggestedDelivery: isSuggestedDelivery,
				SuggestedDate: strSuggestedDate,
				Remarks: strRemark,
				IsShopVisit: blnIsShopVisit,
				IsGuest: blnIsGuest,
				Name: strName,
				Phone: strPhone,
				Email: strEmail,
				Address: strAddress,
				IpAddress: strIpAddress,
			}
		);
}

export default new PlaceOrderService();