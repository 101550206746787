import apiClient from "../helpers/apiClient";

class FlyerService {
	getFlyerList = (shopID, latitude = "", longitude = "") => apiClient().post('/GetFlyers', {
		UserID: 0,
		AuthKey: "",
		FlyerID: 0,
		ShopID: shopID,
		PageSize: 30,
		PageNo: 1,
		Latitude: latitude,
		Longitude: longitude
	});
}

export default new FlyerService();