import LoginService from "../../../../services/auth/login.service";
import actions from "./login.actions";

export const loadLoginAsync = (mobileNumber, password, pushNotificationToken) => (dispatch) => {

	dispatch(actions.loginLoadStart());

	LoginService.getLoginDetails(mobileNumber, password, pushNotificationToken)
		.then((response) => dispatch(actions.loginLoadSuccess(response.data)))
		.catch((error) => dispatch(actions.loginLoadError(error.message)));
};