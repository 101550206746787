import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { loadFlyerAsync } from "../redux/reducers/flyer/flyer.thunks";
import { loadProductsAsync } from "../redux/reducers/products/products.thunks";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { ImageViewer } from "react-image-viewer-dv"
import { useParams } from "react-router-dom";

const Flyers = (props) => {

	const dispatch = useDispatch();
	const { isLoading, shop, errorMessage } = useSelector((state) => state.shopReducer);
	const { isFlyerLoading, flyer, flyerErrorMessage } = useSelector((state) => state.flyerReducer);

	const { shopCode } = useParams();
	console.log(shopCode);

	useEffect(() => {
		if (shop) {
			dispatch(loadFlyerAsync(shop.ShopDetails.ShopID, "", ""));
		}
	}, [shop])

	return (
		<>
			<section style={{ marginTop: 130 }}>
			</section>


			{flyer && flyer.FlyerDatas.length == 0 ? <>

				<section className="section pt-5 pb-5 osahan-not-found-page">
					<Container>
						<Row>
							<Col md={12} className="text-center pt-5 pb-5">
								<h1 className="mt-2 mb-2">No Flyers found</h1>
								<p>Hmm.. No active flyers for today, Please check for tomorrow<br />Please browse our products</p>
								<Link className="btn btn-primary btn-lg" to={"/" + shopCode + "/"}>Browse All Products</Link>
							</Col>
						</Row>
					</Container>
				</section>


			</> : <>
				<section className="section pt-5 pb-5 bg-white">

					{isFlyerLoading ? <ShimmerSimpleGallery card imageHeight={20} caption /> :
						<ImageList sx={{ width: 'auto', height: 'auto' }} rowHeight={250}>
							{flyer.FlyerDatas.map(flyerRow => (

								<>
									<ImageListItem key={flyerRow.img} >
										<ImageViewer>
											<img
												src={`${flyerRow.FlyerImages[0].ImagePath}?w=164&h=164&fit=crop&auto=format`}

												alt={flyerRow.OfferName}
												loading="lazy"
											/>
										</ImageViewer>
										<ImageListItemBar
											title={flyerRow.OfferName}
											subtitle={`views ${flyerRow.Viewers}`}
										/>
									</ImageListItem>


								</>
							))}
						</ImageList>
					}

				</section>
			</>}



		</>
	);
}

export default Flyers;