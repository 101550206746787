import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';

class ProductBox extends React.Component {
  render() {
    return (
      <div className={this.props.boxClass} style={this.props.style}>


        <Tooltip title={this.props.title} placement="bottom">



          {(this.props.onClick) ? <>

            <div onClick={this.props.onClick} >
              <Image src={this.props.image || "https://rig71zmqsudp.singlecart.io/CategoryImage/default.png"}
                onError={(e) => { e.target.onerror = null; e.target.src = "https://rig71zmqsudp.singlecart.io/CategoryImage/default.png" }}
                style={{ borderRadius: 4 }} className={this.props.imageClass}
                alt={this.props.imageAlt} />
              {this.props.title ?
                <p> {this.props.title.trim().length > 8 ? (
                  <>{this.props.title.substring(0, 8).trim() + ".."}</>
                )
                  : <>{this.props.title.trim()}</>
                }</p>
                : ""
              }
              {this.props.counting ?
                <p>{this.props.counting}</p>
                : ""
              }
            </div>
          </> : <>
            <Link onClick={this.props.onClick} to={this.props.linkUrl}>
              <Image src={this.props.image || "https://rig71zmqsudp.singlecart.io/CategoryImage/default.png"} style={this.props.imageStyle} className={this.props.imageClass} alt={this.props.imageAlt} />
              {this.props.title ?
                <p> {this.props.title.trim().length > 10 ? (
                  <>{this.props.title.substring(0, 10) + ".."}</>
                )
                  : <>{this.props.title}</>
                }</p>
                : ""
              }
              {this.props.counting ?
                <p>{this.props.counting}</p>
                : ""
              }
            </Link>

          </>}

          {/* <Link onClick={this.props.onClick} to={this.props.linkUrl}>
          <Image src={this.props.image} style={this.props.imageStyle} className={this.props.imageClass} alt={this.props.imageAlt} />
          {this.props.title ?
            <h6>{this.props.title}</h6>
            : ""
          }
          {this.props.counting ?
            <p>{this.props.counting}</p>
            : ""
          }
        </Link> */}
        </Tooltip>
      </div>
    );
  }
}


ProductBox.propTypes = {
  linkUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  image: PropTypes.string.isRequired,
  imageClass: PropTypes.string,
  imageAlt: PropTypes.string,
  boxClass: PropTypes.string,
  title: PropTypes.string,
  counting: PropTypes.string,
  style: PropTypes.object,
  imageStyle: PropTypes.object
};
ProductBox.defaultProps = {
  imageAlt: '',
  image: '',
  imageClass: '',
  linkUrl: '',
  boxClass: 'products-box',
  title: '',
  counting: '',
  onClick: null,
  style: {},
  imageStyle: {}
}

export default ProductBox;