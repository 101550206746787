import actionTypes from "./login.actionTypes";

const loginLoadStart = () => ({
	type: actionTypes.LOGIN_LOAD_START,
});

const loginLoadSuccess = (loginData) => ({
	type: actionTypes.LOGIN_LOAD_SUCCESS,
	payload: loginData,
});

const loginLoadError = (errorMessage) => ({
	type: actionTypes.LOGIN_LOAD_ERROR,
	payload: errorMessage,
});

export default {
	loginLoadStart,
	loginLoadSuccess,
	loginLoadError,
};